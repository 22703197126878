import { TCampaign, TDonation } from 'gql'
import { asTuple } from './utils'

export abstract class GtmHelper {
  static initTagManager() {
    initDatalayer()
    updateDataLayer({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
  }

  static pushOriginalLocation() {
    initDatalayer()

    // only push it once
    if (!window.dataLayer || window.dataLayer.length) return

    // Save the original URL to the dataLayer
    // see https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
    updateDataLayer({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    })
  }

  static pushProductDetailImpression(campaign: CampaignData) {
    pushEeDataLayer('eeProductDetailImpression', { detail: { products: campaignData(campaign) } })
  }

  static pushAddToBasket(campaign: CampaignData) {
    pushEeDataLayer('eeAddToBasket', { add: { products: campaignData(campaign) } })
  }

  static pushCheckout(campaign: CampaignData) {
    pushEeDataLayer('eeCheckout', { checkout: { actionField: { step: 1 }, products: campaignData(campaign) } })
  }

  static pushCheckoutOptions(paymentMethod: string) {
    pushEeDataLayer('eeCheckout', { checkout: { actionField: { step: 2 } } })
    pushEeDataLayer('eeCheckoutOptions', { checkout_option: { actionField: { step: 2, option: paymentMethod } } })
  }

  static pushPurchase(donation: DonationData) {
    pushEeDataLayer('eePurchase', {
      purchase: {
        actionField: {
          id: `donation#${donation.id}`,
          revenue: (donation.amountCents / 100.0).toFixed(2),
        },
        products: campaignData(
          Object.assign(
            {
              price: (donation.amountCents / 100.0).toFixed(2),
              codonation: (donation.codonationCents / 100.0).toFixed(2),
            },
            donation.campaign
          )
        ),
      },
    })
  }
}

function pushEeDataLayer(event: string, eeData: Record<string, unknown>) {
  updateDataLayer({ event, ecommerce: { ...eeData, currencyCode: 'EUR' } })
}
type CampaignKeys = 'title' | 'id' | 'category'
type CampaignData = Pick<TCampaign, CampaignKeys> & { price?: string; codonation?: string }

type DonationData = Pick<TDonation, 'id' | 'amountCents' | 'codonationCents'> & {
  campaign: Pick<Pick<TDonation, 'campaign'>['campaign'], CampaignKeys>
}
function campaignData({ price, codonation, ...campaign }: CampaignData) {
  return asTuple([
    {
      name: campaign.title,
      id: `campaign#${campaign.id}`,
      category: campaign.category,
      quantity: 1,
      price,
      codonation,
    },
  ])
}

function updateDataLayer(data: DataLayerItem) {
  if (!window.dataLayer || !window.dataLayer.push) {
    return console.error('no dataLayer')
  }

  window.dataLayer.push(data)
}

function initDatalayer() {
  window.dataLayer = window.dataLayer || []
}

export const trackFormErrors = (errors: Record<string, string>, category: string) => {
  // do we want the same for posthog?
  // Object.keys(errors).forEach((errorKey) => gaEvent(category, `field ${errorKey}`, errors[errorKey], '', true))
}
