import { createMedia } from '@artsy/fresnel'

// keep in sync with site.variables
const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    fiveHundred: 500, // only used for SingleColumnLayout
    tablet: 768,
    // currently unused:
    // computer: 992,
    // largeScreen: 1200,
    // widescreen: 1920,
  },
})

export const mediaStyles = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia
