import { useViewerQuery } from 'gql'
import React from 'react'
import { ViewerContext } from 'shared'

const ViewerProvider: React.FC<{}> = ({ children }) => {
  const { data, loading } = useViewerQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    // dont fetch viewer during sign out or you end up with a race condition
    skip: !!window.location.pathname.match(/^\/sign_out([?#/].*)?$/),
  })

  return (
    <ViewerContext.Provider
      value={{
        viewer: data && data.viewer,
        viewerLoading: loading,
      }}
    >
      {children}
    </ViewerContext.Provider>
  )
}
export default ViewerProvider
