import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Routes, ViewerContext } from 'shared'

export const ProtectedRoute = () => {
  const location = useLocation()
  const { viewer, viewerLoading } = useContext(ViewerContext)

  if (viewerLoading || !location) return null

  const { pathname, search } = location

  if (viewer) {
    return <Outlet />
  }

  return <Navigate replace to={Routes.signUpPath()} state={{ prevLocation: pathname + search }} />
}
