import React from 'react'

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void
type ErrorHandlingComponent<Props extends {}> = (props: Props, error?: Error) => JSX.Element | null

type ErrorState = { error?: Error }

export default function Catch<Props extends {}, State extends ErrorState = ErrorState>(
  Component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler,
  getDerivedStateFromError: (error: Error) => State = (error: Error) => ({ error }) as State
): React.ComponentType<Props> {
  function Inner({ props, error }: { error?: Error; props: Props }) {
    return <Component {...props} error={error} />
  }

  return class extends React.Component<Props, ErrorState> {
    static displayName = 'Catch'
    state: State = {
      error: undefined,
    } as State

    static getDerivedStateFromError(error: Error) {
      return getDerivedStateFromError(error)
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info)
      }
    }

    render() {
      return <Inner error={this.state.error} props={this.props} />
    }
  }
}
