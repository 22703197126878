import { Maybe } from 'gql'
import React from 'react'
import { QueryHelper, TrackingViaContext } from 'shared'

const TrackingViaProvider = ({ children }: { children?: React.ReactNode }) => {
  let trackingVia: Maybe<string> | undefined = QueryHelper.parseQuery().tracking_via as string

  try {
    if (localStorage) {
      if (trackingVia) {
        localStorage.setItem('trackingVia', trackingVia)
      } else {
        trackingVia = localStorage.getItem('trackingVia')
      }
    }
  } catch (_) {
    // client browser has set very restrictive privacy settings
  }
  trackingVia = trackingVia || ''
  const value = { trackingVia }

  return <TrackingViaContext.Provider value={value}>{children}</TrackingViaContext.Provider>
}
export default TrackingViaProvider
