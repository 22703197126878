import { useEffect } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import data from '../../../me-theme/theme.current.json'

export const theme = data

export const CssVarSetter: React.FC<{}> = () => {
  useEffect(() => {
    const root = document.documentElement
    Object.entries(theme).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, String(value))
    })
  }, [])

  return null
}
