import { useContext, useEffect } from 'react'
import { GtmHelper } from 'shared/helpers'
import { AppContext } from 'shared/helpers/context'

function useGTMConfig() {
  const ctx = useContext(AppContext)
  if (ctx.appConfigLoading) return
  return ctx.gtm
}
function useGoogleTagManager(acceptTracking?: boolean) {
  const cfg = useGTMConfig()

  useEffect(() => {
    GtmHelper.pushOriginalLocation()
  }, [])

  useEffect(() => {
    if (!cfg || !acceptTracking) return
    const { auth, id, preview } = cfg
    GtmHelper.initTagManager()

    const firstScript = document.getElementsByTagName('script')[0]
    const gtmScript = document.createElement('script')
    gtmScript.async = true
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x`
    firstScript.parentNode?.insertBefore(gtmScript, firstScript)
  }, [cfg, acceptTracking])
}

export default useGoogleTagManager
