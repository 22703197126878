import React from 'react'
import { Helmet } from 'react-helmet'
import { Icon } from 'semantic-ui-react'
import { Routes, t } from 'shared'

export const GenericNotFound = () => (
  <div style={{ textAlign: 'center', paddingTop: 30 }}>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="prerender-status-code" content="404" />
    </Helmet>

    {qm(QmSizes.Huge)}
    {qm(QmSizes.Massive)}
    {qm(QmSizes.Huge)}
    <h1>404</h1>
    <p>{t('misc.error_404')}</p>
    <a href={Routes.rootPath()}>{t('link_titles.back_to_home')}</a>
  </div>
)

enum QmSizes {
  Huge = 'huge',
  Massive = 'massive',
}

const qm = (size: QmSizes) => <Icon name="question" size={size} />
