import classNames from 'classnames'
import { TQuickinfoItem, useQuickinfosQuery } from 'gql'
import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'
import { useViewer } from 'shared/helpers'
import { DSIHtml } from '../dsi_html'
import styles from './quickinfo.module.css'
import { QuickinfoProps } from './types'

export const Quickinfo = (props: QuickinfoProps) => {
  const viewer = useViewer()
  if (!viewer || !viewer.showQuickinfos) return null

  return <Content {...props} />
}

const Content = ({ type, recordId, placement = 'inline' }: QuickinfoProps) => {
  const { data } = useQuickinfosQuery({ variables: { type, recordId } })
  const [dismissed, setDismissed] = useState(false)
  if (dismissed || !data?.viewer?.quickinfos?.length) return null

  return (
    <ul className={classNames(styles.container, styles[placement])}>
      <li onClick={() => setDismissed(true)} className={styles.closer}>
        ╳
      </li>
      {data.viewer.quickinfos.map((e, i) => (
        <li key={i}>
          <Item {...e} />
        </li>
      ))}
    </ul>
  )
}

const Item = ({ title, url, urlRisky, detailsHtml }: TQuickinfoItem) => {
  let el
  if (url) {
    el = (
      <a
        href={url}
        onClick={(e) => {
          if (urlRisky && !confirm(`${title} - are you sure?`)) e.preventDefault()
        }}
      >
        {url.includes('/backoffice') ? '☼' : ''}
        {title}
      </a>
    )
  } else {
    el = <span>{title}</span>
  }
  if (detailsHtml) return <Popup content={<DSIHtml>{detailsHtml}</DSIHtml>} trigger={el} />
  return el
}
