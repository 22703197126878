/**
 * @deprecated Use <Media lessThan="tablet"> instead.
 */
export const isMobile = () => window.innerWidth < 768

export const truncate = (text: string, { length }: { length: number }) => {
  return text.length > length ? `${text.substring(0, length)}…` : text
}

export const quotationMarks = (text: string) => `„${text}“`
