import { TViewerQuery } from 'gql'
import { createContext } from 'react'
interface Gtm {
  auth: string
  id: string
  preview: string
}

interface Paypal {
  clientId: string
  codonationMerchantId: string
  env: string
}

interface Stripe {
  key: string
}

interface FriendlyCaptcha {
  verifyEndpoint: string
  puzzleEndpoint: string
  secret: string
  sitekey: string
}

interface Posthog {
  apiKey: string
  apiHost: string
}

export interface AppProps {
  hostname: string
  paymentLiveMode: string
  gtm: Gtm
  paypal: Paypal
  stripe: Stripe
  friendlyCaptcha: FriendlyCaptcha
  posthog: Posthog
}

export type ViewerContextType = {
  viewer?: TViewerQuery['viewer']
  viewerLoading?: boolean
}
export type TrackingViaContextType = {
  trackingVia?: string
}
export type AppContextType = { appConfigLoading: true } | (AppProps & { appConfigLoading: false })
export const AppContext = createContext<AppContextType>({ appConfigLoading: true })
export const ViewerContext = createContext<ViewerContextType>({} as ViewerContextType)
export const TrackingViaContext = createContext<TrackingViaContextType>({} as TrackingViaContextType)
