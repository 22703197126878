import React, { useEffect, useRef, useState } from 'react'
import posthog from 'posthog-js'
import useClickAway from 'react-use/lib/useClickAway'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { LogoCompact, t, userAgentIsBot, I18nHtml, isMobile } from 'shared'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import styles from './cookie_banner.module.css'
import { CookieBannerModal } from './cookie_banner_modal'
import useGoogleTagManager from './use_google_tag_manager'
import usePosthog from './use_posthog'
import classNames from 'classnames'
import { useBrowserEventMutation } from 'gql'

const cookies = new Cookies()

// Cookie has been renamed to force the banner again on all users
const trackingCookieName = 'betterplace-me-tracking-accepted'

export const CookieBanner = () => {
  const [sendBrowserEvent] = useBrowserEventMutation()
  const [trackingChoice, setTrackingChoice] = useState(cookies.get(trackingCookieName))
  const [informationRequested, setInformationRequested] = useState(false)

  const refCookieBanner = useRef<HTMLDivElement | null>(null)

  const handleCookieChange: CookieChangeListener = (cookie) => {
    if (cookie.name === trackingCookieName) setTrackingChoice(cookie.value)
  }

  const acceptTracking = () => {
    cookies.set(trackingCookieName, 'accepted', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_in_capturing()) {
      // This needs to wrapped in a timeout, otherwise the capture event won't be fired
      setTimeout(() => {
        posthog.opt_in_capturing()
      })
    }

    posthog._start_queue_if_opted_in()

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-accepted', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  const rejectTracking = () => {
    cookies.set(trackingCookieName, 'rejected', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_out_capturing()) {
      posthog.opt_out_capturing()
    }

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-rejected', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  const requestInformation = () => {
    setInformationRequested(true)
  }

  useEffect(() => {
    cookies.addChangeListener(handleCookieChange)
    return () => cookies.removeChangeListener(handleCookieChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (trackingChoice !== 'rejected' && trackingChoice !== 'accepted') {
      sendBrowserEvent({
        variables: { eventName: 'tracking-banner-show', deviceType: isMobile() ? 'mobile' : 'desktop' },
      })
    }
  }, [sendBrowserEvent, trackingChoice])

  useGoogleTagManager(trackingChoice === 'accepted')
  usePosthog(trackingChoice === 'accepted')
  if (trackingChoice === 'rejected' || trackingChoice === 'accepted') return null

  if (userAgentIsBot()) return null

  return (
    <div className={styles.cookieBanner} ref={refCookieBanner}>
      {!informationRequested && <Autorejecter refCookieBanner={refCookieBanner} />}
      <Grid centered className={styles.cookieContentWrapper}>
        <Grid.Row>
          <Grid.Column width={2} only="computer">
            <div className={styles.logoContainer}>
              <LogoCompact color="#AECFD1" className={styles.logo} />
            </div>
          </Grid.Column>
          <Grid.Column mobile={11} computer={10}>
            <h1>{t('cookie_banner.header')}</h1>
            <I18nHtml i18nKey="cookie_banner.message_html" />
            <div className={styles.actionContainer}>
              <div className={styles.linkWrapper}>
                <CookieBannerModal requestInformation={requestInformation} />
              </div>
              <div className={styles.buttonWrapper}>
                <Button className={styles.rejectButton} onClick={rejectTracking} primary basic>
                  {t('cookie_banner.reject_button_label')}
                </Button>
                <Button primary onClick={acceptTracking}>
                  <Icon name="check" /> {t('verbs.agree')}
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

// component to auto-reject cookies some time after page interaction.
// must be unmounted to cancel rejection.
const Autorejecter = ({ refCookieBanner }: { refCookieBanner: React.MutableRefObject<HTMLDivElement | null> }) => {
  const [sendBrowserEvent] = useBrowserEventMutation()
  const [pageUsed, setPageUsed] = useState(false)
  const { y } = useWindowScroll()
  const refFirstScroll = useRef(true)

  // track scrolling on page
  useEffect(() => {
    if (refFirstScroll.current) {
      refFirstScroll.current = false
      return
    }
    setPageUsed((used) => used || true)
  }, [y])

  const rejectTracking = () => {
    cookies.set(trackingCookieName, 'rejected', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_out_capturing()) {
      posthog.opt_out_capturing()
    }

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-rejected-by-timeout', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  // track clicks outside cookie banner
  useClickAway(refCookieBanner, () => setPageUsed((used) => used || true))

  // if either happened, start timeout to reject tracking
  useEffect(() => {
    if (!pageUsed) return
    const timer = setTimeout(() => rejectTracking(), 10000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUsed])

  return null
}

export const CookieBannerLink = ({ className, content }: { className?: string; content?: string }): JSX.Element => {
  /** @type {React.MouseEventHandler} */
  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    cookies.set(trackingCookieName, 'edit', { path: '/', expires: new Date(2099, 1, 1) })
  }

  return (
    <button className={classNames([className, styles.cookieBannerLink])} onClick={handleClick}>
      {content}
    </button>
  )
}
