import { TViewerQuery } from 'gql'
import React, { lazy, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { QueryHelper, Routes, t, ViewerContext } from 'shared'
import './header_styles.sass'
import Logo from './logo'

const Header = () => {
  const { viewer } = useContext(ViewerContext)
  const searchQuery = (QueryHelper.parseQuery().q as string) || ''

  return (
    <div className="ui mb-medium me-header menu">
      <Link to={Routes.rootPath()} className="item me-header--logo">
        <Logo />
      </Link>

      <div className="right menu">
        <SearchMenuItem query={searchQuery} />
        <SignInMenuItem visible={!viewer} />
        <ViewerMenuItem viewer={viewer} />
      </div>
    </div>
  )
}
export default Header

type SearchMenuItemsProps = Pick<SearchInputProps, 'query'>

type SearchInputProps = {
  query: string
  onChange: React.ChangeEventHandler
}

const SearchMenuItem = (props: SearchMenuItemsProps) => {
  const [query, setQuery] = useState(props.query)
  const [isOpen, setIsOpen] = useState(!!props.query)
  const navigate = useNavigate()

  const submitSearch: React.FormEventHandler = (event) => {
    event.preventDefault()
    if (query) navigate(Routes.campaignsPath({ q: query }))
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => setQuery(e.target.value)

  if (isOpen) {
    return (
      <div className="item me-header--search">
        <form className="ui form" onSubmit={submitSearch}>
          <div title={t('search.tooltip')} className="ui large fluid icon input me-header--search-input">
            <SearchInput query={query} onChange={handleChange} />
            <i aria-hidden="true" className="search link icon" onClick={submitSearch} />
          </div>
        </form>
      </div>
    )
  } else {
    return (
      <div className="link item" onClick={() => setIsOpen(true)}>
        <i aria-hidden="true" className="search large icon" />
        <span className="hide-on-mobile">{t('verbs.search')}</span>
      </div>
    )
  }
}

const SearchInput = (props: SearchInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    ref.current && ref.current.focus()
  }, [])

  return <input ref={ref} type="text" {...props} />
}

// ViewerMenu depends on SUI React for various features,
// so it is loaded async when a viewer is logged in
// to keep the Header fast for all users.
const ViewerMenuItem = ({ viewer }: { viewer: TViewerQuery['viewer'] }) => {
  const ViewerMenu = useMemo(() => lazy(() => import('./viewer_menu')), [])
  if (!viewer) return null

  return (
    <Suspense fallback={null}>
      <ViewerMenu {...{ viewer }} />
    </Suspense>
  )
}

const SignInMenuItem = ({ visible }: { visible?: boolean }) => {
  if (!visible) return null

  return (
    <Link className="item" to={Routes.signInPath()}>
      <i aria-hidden="true" className="user circle large icon" />
      <span className="hide-on-mobile">{t('verbs.sign_in')}</span>
    </Link>
  )
}
