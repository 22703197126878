import { useCallback, useState } from 'react'
import { isFalsy } from 'shared'

export const useBooleanState = <T extends boolean | undefined | null>(
  initialState: T
): [state: T | boolean, setToTrue: () => void, setToFalse: () => void, toggle: () => void] => {
  const [state, setState] = useState<T | boolean>(initialState)
  const setToTrue = useCallback(() => setState(true), [])
  const setToFalse = useCallback(() => setState(false), [])
  const toggle = useCallback(() => setState(isFalsy), [])
  return [state, setToTrue, setToFalse, toggle]
}
