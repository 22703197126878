import { useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { useBrowserEventMutation } from 'gql'
import { isMobile, t, useBooleanState } from 'shared'
import styles from './cookie_banner.module.css'
import { I18nHtml } from '../i18n_html'

export const CookieBannerModal = ({ requestInformation }: { requestInformation: () => void }) => {
  const [sendBrowserEvent] = useBrowserEventMutation()
  const [open, setOpen, setClose] = useBooleanState(false)

  useEffect(() => {
    if (open) {
      sendBrowserEvent({
        variables: {
          eventName: 'tracking-more-information',
          deviceType: isMobile() ? 'mobile' : 'desktop',
        },
      })
    }
  }, [open, sendBrowserEvent])

  return (
    <Modal
      size={!isMobile ? 'fullscreen' : undefined}
      onClose={setClose}
      className={styles.modal}
      open={open}
      trigger={
        <a
          onClick={() => {
            setOpen()
            requestInformation()
          }}
          className={styles.modalTrigger}
        >
          {t('misc.more_information')}
        </a>
      }
      closeIcon
    >
      <Modal.Header>{t('cookie_banner.details_header')}</Modal.Header>
      <Modal.Content>
        <I18nHtml i18nKey="cookie_banner.details_message_html" />
      </Modal.Content>
    </Modal>
  )
}
