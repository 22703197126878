const bots = [
  'applebot',
  'baiduspider',
  'bingbot',
  'bitlybot',
  'bufferbot',
  'chrome-lighthouse',
  'developers.google.com/+/web/snippet',
  'discordbot',
  'embedly',
  'facebookexternalhit',
  'flipboard',
  'google page speed',
  'googlebot',
  'linkedinbot',
  'nuzzel',
  'outbrain',
  'pinterest/0.',
  'quora link preview',
  'qwantify',
  'redditbot',
  'rogerbot',
  'showyoubot',
  'skypeuripreview',
  'slackbot',
  'tumblr',
  'twitterbot',
  'vkshare',
  'w3c_validator',
  'whatsapp',
  'www.google.com/webmasters/tools/richsnippets',
  'yahoo',
]

const botsRegexp = new RegExp(bots.join('|'), 'i')

export const userAgentIsBot = () => botsRegexp.test(navigator.userAgent)
